
.split-button {
	.text {
		padding: .375rem .75rem;
    flex: 1;
	}
	.icon {
		padding: .44rem .88rem;
    border-bottom-right-radius: .25rem;
    border-top-right-radius: .25rem;
    background: #efefef36;
	}
}

.future-action {
  &:hover {
    color: $default-color;
  }
}

.date-dropdown-btn {
  .input-group-text {
    background: #2d3152;
    color: white;
    border: unset;
  }
}

.btn-outline-primary-nohover, .btn-outline-primary-nohover:hover {
  color: #007bff;
  border-color: #007bff;
}

.btn-primary-nohover, .btn-primary-nohover:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-disabled-nohover, .btn-outline-disabled-nohover:hover {
  color: #6c757d;
  border-color: #ddd;
}
.btn-disabled-nohover, .btn-disabled-nohover:hover {
  color: #fff;
  background-color: #ddd;
  border-color: #ddd;
}

.btn-round-navy {
  color: #313557;
  border-color: #313557;
  border-radius: 50%;
}

.btn-round-navy:hover {
  color: #fff;
  background-color: #313557;
  border-color: #313557;
}
.btn-round-navy:focus {
  box-shadow: unset;
}


.btn-navy {
  color: #313557;
  border-color: #313557;
}

.btn-navy:hover {
  color: #fff;
  background-color: #313557;
  border-color: #313557;
}
.btn-navy:focus {
  box-shadow: unset;
}
