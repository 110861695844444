@mixin  extraSmallDisplay() {
    @media (max-width: 567px) {
        @content;
    }
}

@mixin  smallDisplay() {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin  mediumDisplay() {
    @media (min-width: 768px) and (max-width: 1023px) {
        @content;
    }
}
@mixin tabToTabletDisplay {
    @media (min-width: 1024px) and (max-width: 1262px) {
        @content;
    }
}
@mixin  tabletDisplay() {
    @media (min-width: 990px) and (max-width: 1300px) {
        @content;
    }
}

@mixin tabDisplay() {
    @media (min-width: 768px) and (max-width: 990px) {
        @content;
    }
}

@mixin mediumTabDisplay(){
    @media (min-width: 768px) and (max-width: 820px) {
        @content;
    }
}

@mixin  largeDisplay() {
    @media (max-width: 1025px) {
        @content;
    }
}

@mixin  extraLargeDisplay() {
    @media (max-width: 2050px) {
        @content;
    }
}