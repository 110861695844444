.insights-header {
    background-color: #323657;
    /* height: 52px; */
    padding: 8px !important;
    margin: 0px -32px;
}
.profile-icon, 
.logout-icon {
    color: white;
    font-size: 24px !important;
    vertical-align: middle;
    margin: 5px 10px 0 0;
}
span.header-text {
    color: white;
    font-size: 12px;
    margin: 0 15px 0 0;
    line-height: 3;
}
.profile-icon, .logout-icon {
    color: white;
    font-size: 24px !important;
    vertical-align: middle;
    margin: 0px 5px 0 0;
}
.insights-header {
    background-color: #323657;
    /* height: 52px; */
    padding: 8px !important;
    margin: 0px -32px 20px -32px;
}
.sign-up {
    line-height: 3;
}
.me-auto {
    text-align: right;
}

.trends-chart-dropdown-contain {
    width: 100%;
    text-align: right;
}

.trends-chart-dropdown {
    display: inline-block;
    margin: 0 auto;
}
.bold-text {
    font-weight: 800;
}
.m-t-5 {
    margin-top: 5px !important;
}
.m-t-10 {
    margin-top: 10px !important;
}
.m-m-t-15 {
    margin-top: -15px !important;
}
.m-b-10 {
    margin-bottom: 10px !important;
}
.m-t-15 {
    margin-top: 15px !important;
}
.p-t-35 {
    padding-top: 35px !important;
}
.m-t-150 {
    margin-top: 150px !important;
}
.filter-insights-modal .branch1-checkbox-area {
    position: relative;
}
.filter-insights-modal .branch1-checkbox {
    /* margin-top: -20px; */
    position: absolute !important;
    top: 3px !important;
}
.filter-insights-modal .branch1-checkbox-label {
    margin-left: 20px;
}
.filter-insights-modal .custom-branch-label {
    font-weight: 600;
    font-size: 12px;
}
.filter-insights-modal .select__control {
    max-height: 100px;
    overflow: auto;
}
.terms-conditions-modal {
    font-size: 14px;
}
.terms-conditions-modal .t-c-title {
    text-align: center;
    font-size: 26px;
    font-weight: bold;
}
.terms-conditions-modal .anchor-tag {
    color: blue;
    text-decoration: underline;
}
.invalid-field-error-message-modal {
    padding: 10px;
}
.invalid-field-error-message-modal .modal-title {
    font-weight: bold;
    /* font-size: 15px; */
    color: #ed3920;
}
.amex-contactus-modal .user-msg {
        font-weight: 600;
}
.amex-contactus-modal .btn-info {
    background-color: #006fcf !important;
      border-color: #006fcf !important;
  }

