.heading {
	margin: 1.66rem 0 .83rem;
	font-size: 2rem;
	.heading-icon {
		font-size: 2rem;
		margin-bottom: 6px;
		vertical-align: middle;
		margin-right: 10px;
	}
	.search {
		font-size : 2rem;
		.reactselect__value-container {
		  padding : 0.6rem;
		  font-size : 1rem;
		}
		.reactselect__menu {
			font-size: 1rem;
		}

		.reactselect__indicator.reactselect__dropdown-indicator {
		  padding : .94rem;
		}
		.reactselect__control {
		  border: unset !important;
		  box-shadow: 0px .39rem .39rem -0.44rem #80808038;
		}
		.reactselect__dropdown-indicator {
		  background : #2d3152;
		  border-top-right-radius: .16rem;
		  border-bottom-right-radius: .16rem;
		}
		.reactselect__indicator-separator {
		  display : none;
		}
		.reactselect__indicator.reactselect__dropdown-indicator {
		  color : white;
		  svg {
		    height : 1.66rem;
		    width : 1.66rem;
		  }
		}

		.reactselect__control.reactselect__control--is-focused, .reactselect__control.krowd__control--is-focused:hover {
		  border : 1px solid gray;
		  box-shadow : 0 0 0 1px transparent;
		}

		.reactselect__indicator.reactselect__dropdown-indicator {
			padding: 0.5rem;
		}
	}
	
}