.loadNone {
    display: none !important;
}

.loadPage {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 999;
    /* // background:#e4dada4d;
    // height: 2em;
    // width: 2em; */
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.load {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    position: fixed;
    background: #ffffff;
    z-index: 1000;
  
    & + div {
      height: 100vh;
      overflow: hidden;
    }
  
    &.loaded {
      animation: ease loaded 0.5s;
    }
  }
  
//   .load__icon {
//     animation: linear load 2s infinite;
//     margin: auto;
//     width: 32px;
//     height: 32px;
//   }
  
//   .load__icon-wrap {
//     margin: auto;
//   }
  
  @keyframes load {
    from {
      transform: rotate(0deg) scale(2);
    }
    to {
      transform: rotate(360deg) scale(2);
    }
  }
  
  @keyframes loaded {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .load__icon {
    animation: linear load 2s infinite;
    width: 32px;
    height: 32px;
  }
  
  .load__icon-wrap {
    margin: auto;
  }
  
  .load {
    height: calc(100vh - 16px);
    width: 100%;
    display: flex;
    align-items: center;
  }
  
  @keyframes load {
    from {
      transform: rotate(0deg) scale(2);
    }
    to {
      transform: rotate(360deg) scale(2);
    }
  }
  

