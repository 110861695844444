.campain_label {
  @media screen and (max-width: "840px") {
    height: 50px;
  }
}
._imgupload_field {
  height: 38px;
}
.onDragOver {
  width: 100% !important;
  position: relative !important;
  left: 50%;
  bottom: -51%;
  color: white;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  // height: 261px;
  width: 100%;
  font-family: sans-serif;
  border: 1px solid #efefef;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 5px 10px #efefef;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .campagnCard {
    padding: 1rem 2px !important;
  }
  .campaignBody {
    padding: 1.25rem 6px !important;
  }

  .card .form hr.divider {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}
.dotsCircle {
  height: 100px;
  width: 100px;
  background-color: #bbb;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dotsCircle:focus {
  outline: none !important;
}
.onDragOverCustmized {
  background: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}
.fileName {
  color: black;
  position: absolute;
  font-size: 12px;
  text-align: center;
  transition: all 0.3s;
  z-index: 10;
  width: 100%;
  line-height: 12px;
  margin: 0;
  top: calc(50% - 6px);
}

.fileDelete {
  transition: all 0.3s;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  color: red;
  font-size: 15px;
  text-transform: uppercase;
}

.file_add {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-grow: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  overflow: hidden;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  // background-color: #8a8d91;
  border-color: transparent;
  text-align: left;
  border-width: 0px;
  // height: 100px;
  // width: 300px;
  border-radius: 6px;
  padding: 0px;
  cursor: pointer;
  border-style: solid;
  margin-right: 15px;
  margin-left: 15px;
}

.file_add_profile {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-grow: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  overflow: hidden;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  // background-color: #8a8d91;
  border-color: transparent;
  text-align: left;
  border-width: 0px;
  // height: 100px;
  width: 300px;
  border-radius: 6px;
  padding: 0px;
  cursor: pointer;
  border-style: solid;
  margin-right: 15px;
  margin-left: 15px;
}

.file_Plus_Icon {
  position: relative;
  display: inline;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  height: 24px;
  font-size: 24px;
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0);
  font-family: SkypeAssets-Light;
  padding: 0px;
  cursor: pointer;
}
.list_img {
  // width: 248px;
  // height: 263px;
  // background-repeat: no-repeat;
  // background-position: center center;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.center_align:hover .overlay {
  opacity: 1;
  // display: none;
}
.center_align:hover .list_img {
  // opacity: 1;
  // display: none;
  filter: blur(5px);
  -webkit-filter: blur(5px);
}
.center_align:hover .icondisplay1 {
  // color: red;
  z-index: 2;
}
.center_align:hover .icondisplay2 {
  // color: red;
  z-index: 2;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  // background-color: #008CBA;
}

.icondisplay1 {
  color: gray;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-125%, -50%);
  -ms-transform: translate(-125%, -50%);
  transform: translate(-125%, -50%);
  text-align: center;
}
.icondisplay2 {
  color: gray;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  text-align: center;
}
.Img__card {
  border: 1px solid #efefef;
  height: 180px;
  // width: 318px;
  width: 100%;
  margin-bottom: 0px;
  padding: 0px;
  // @media screen and (min-width:){

  // }
}
.images_scrollbar {
  height: calc(100vh - 240px);
  min-height: 359px;
}
.hideFileTxt {
  // width: 0px;
  // visibility: hidden;
  height: 33px;
  width: 200px;
}
.go2646822163 {
  height: calc(1.5em + 0.75rem + 6px);
}
.center_align {
  justify-content: center;
  display: flex;
  padding: 5px;
}
.dropdown__img {
  // border-radius: 20px;
  height: 65px;
  width: 100px;
  // display: none;
}
.table__img {
  border-radius: 20px;
  height: 40px;
  width: 40px;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.custom-btn {
  border: 2px solid #007bff;
  color: #007bff;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}
.uploadBtnCustomized {
  padding: 6px 25px !important;
}
.custom-btnCustomized {
  border: 2px dotted transparent !important;
  padding: 0 !important;
  background-color: transparent !important;
  text-align: center !important;
  text-align: -webkit-center !important;
}
.main-file-uploadHeading {
  margin-left: 150px;
}
.main-file-upload {
  background-color: #8080802b;
  margin: 10px 150px;
  padding: 6.25rem 1.25rem;
}
@media screen and (max-width: 1024px) {
  .main-file-upload {
    margin: 0;
  }
  .main-file-uploadHeading {
    margin-left: 3px;
  }
}
.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.custom-btn-img {
  // border: 2px solid #007bff;
  // color: #007bff;
  background-color: white;
  // padding: 8px 20px;
  // border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  // height: 150px;
  // width: 150px;
  // border-radius: 75px;
  border: none;
}
.profile_photo {
  width: 8.33rem;
  height: 8.33rem;
  border-radius: 50%;
  padding: 0.19rem;
  background-color: "#FFFFFF";
  transition: 1s ease;
  border-style: solid;
  opacity: 0;
}
.custom-btn-img input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.custom-file-input-profile {
  position: relative;
  z-index: 2;
  width: 100%;
  height: $custom-file-height;
  margin: 0;
  -webkit-transform: translate(43%, -212%);
  -ms-transform: translate(43%, -212%);
  transform: translate(43%, -212%);
  text-align: center;
  opacity: 0;
}
.icondisplay3 {
  color: white;
  font-size: 24px !important;
  position: absolute;
  top: 14%;
  left: 47%;
  -webkit-transform: translate(48%, -184%);
  -ms-transform: translate(48%, -184%);
  transform: translate(48%, -184%);
  text-align: center;
  display: none !important;
}

.photo-container:hover .icondisplay3 {
  color: #007bff;
  z-index: 2;
  display: block !important;
  // background-color: rgba(0, 0, 0, 0.5);
  // width: 50px;
}
// .upload__btn{
//   background-color: rgba(0, 0, 0, 0.9);
//   position: absolute;
//   top: 14%;
//   left: 47%;
//   -webkit-transform: translate(35%, -187%);
//   -ms-transform: translate(35%, -187%);
//   transform: translate(35%, -187%);
//   text-align: center;
//   display: none !important;
// }
// .upload__btn:hover{
//   z-index: 2;
//   display: block !important;
// }
.error_asterisk {
  color: red;
  font-weight: bold;
}
.profile_container:hover .profile__photo {
  filter: blur(5px);
  -webkit-filter: blur(5px);

  // .icondisplay3{
  //   color: #007bff;
  //   z-index: 2;
  //   display: block !important;
  //   // background-color: rgba(0, 0, 0, 0.5);
  //   // width: 50px;
  // }
}
// .profile__photo{
//   box-shadow: 0 0 0 0.2rem #3ab0c380;
// }
// .profile_container:focus .profile__photo{
//  filter: blur(5px);
//   -webkit-filter: blur(5px);
// }
.profile_container:hover .icondisplay3 {
  color: #007bff;
  z-index: 2;
  display: block !important;
}
.icondisplay3:focus {
  box-shadow: 0 0 0 0.2rem #3ab0c380;
}
// .profile_container:hover .icondisplay3{
//   color: #007bff;
//   z-index: 2;
//   display: block !important;
//   // background-color: rgba(0, 0, 0, 0.5);
//   // width: 50px;
// }
.preview_list {
  display: flex;
  justify-content: space-between;
}
.preview__value {
  font-weight: bold;
}
.chartjs-render-monitor {
  // animation: chartjs-render-animation 0.001s;
  // max-height: 200px !important;
  display: inline-block !important;
}
.ReactCrop__image {
  // min-height: 300px;
  min-width: 50%;
}

.img-container {
  width: 640px;
  height: 480px;
  // float: left;
}

.img-preview-box {
  width: 50%;
}
.img-preview {
  // width: 200px;
  height: 200px;
  // float: left;
  margin-left: 10px;
}
.preview__scrollbar {
  height: calc(100vh - 210px);
  min-height: 400px;
}
.preview__img {
  border-radius: 50px;
  width: 100px;
}
.css-1pcexqc-container {
  height: calc(1.5em + 0.75rem + 5px);
}
.preview__summary__card {
  // box-shadow: 0px 0.39rem 1.39rem -0.44rem;
  z-index: 2;
  margin: 10px;
}
.react-bootstrap-table-pagination-total {
  // position: absolute;
  // top: 8px;
}
.btn-group {
  position: absolute;
  display: -webkit-inline-flex;
  display: inline-flex;
  flex-direction: row-reverse;
  vertical-align: middle;
  right: 0;
}
.tabRootFirst {
  height: 40px;
  width: 40px;
  // background-color: #22B14C;
  // background-color: #C07D45;
  background-color: #0e4768;
  border-radius: 50%;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
  padding: 7px;
  // border-color: black;
  // border: 5px solid;
}
.notVistedDiv {
  height: 40px;
  width: 40px;
  // background-color: red;
  background-color: #fafcfe;
  // background-color: #C07D45;

  border-radius: 50%;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
  padding: 7px;
  // border-color: black;
  // border: 5px solid;
}
.activeDiv {
  height: 40px;
  width: 40px;
  // background-color: white;
  // background-color: #C07D45;
  background-color: #0e4768;
  border-radius: 50%;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
  padding: 7px;
  // border-color: black;
  // border: 5px solid;
}

.succesGif {
  height: 100px !important;
}
.headingOfTrain {
  width: 240px;
  text-align: center;
  // color: #baa38d;
  // color: '#0e4768';
  color: black;
}
@media screen and (max-width: 1024px) {
  .headingOfTrain {
    text-align: center;
    padding-bottom: 5px;
    // color: #baa38d;
    // color: '#0e4768';
    width: 80px;
    color: black;
  }
  .guestUserCards {
    margin-left: 1px;
    margin-right: 1px;
  }
  .hideTrainComponent {
    display: none !important;
  }
  .main-file-uploadSelect {
    margin: 0;
  }
  .table_action_btnInTrain {
    height: 28px;
  }
}
.guestUserCards {
  margin-left: 18px;
  margin-right: 18px;
}
.blurText {
  filter: blur(3px);
  -webkit-filter: blur(3px);
}
.comingSoonFontSize {
  font-size: 16px;
}
.rc-slider-disabled {
  background-color: transparent !important;
}
.weeksDaysBarDiv {
  width: 8px;
  height: 2px;
  margin: 16px 4px 0 4px;
  border-radius: 5px;
  background-color: #327bfa;
}
.weekDaysDiv {
  height: 30px;
  width: 33px;
  border-radius: 50%;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
  padding: 9px 0 9px 0;
  background-color: #22b14c;
  color: white;
  cursor: pointer;
  font-size: 10px;
}
.weekDayNotSelected {
  height: 30px;
  width: 33px;
  border-radius: 50%;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
  padding: 9px 0 9px 0;
  background-color: grey;
  color: white;
  font-size: 10px;
  cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@media screen and (max-width: 576px) {
  .weeksDaysBarDiv {
    width: 0;
    margin: 2px;
  }
}

.errorTextDiv {
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}
.infoIcon {
  margin: 3px 3px;
  margin-top: 10px;
}
.positionMerchantIdInputClass {
  position: relative;
}
.positionMerchantIdInputField {
  position: absolute !important;
  width: 100% !important;
  top: -15px;
}
.progressCustom {
  height: 3px !important;
  margin-top: 20px !important;
  // width: 30%!important;
  border-radius: 0 !important;
}
.progresBarClr {
  // background-color:#C07D45!important;
  background-color: #0e4768 !important;
  width: 200px;
}
.trainComponentBg {
  background-color: #fafcfe;
}
.barDiv {
  width: 200px;
  // background-color: black;
  // height: 7px;
  // margin: 14px 6px 0 6px;
  // border-radius: 5px;
}
@media screen and(max-width:1024px) {
  .btn-group {
    position: relative;
    display: block;
    right: 0;
    width: 100%;
  }
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
  font-size: 24px !important;
}
.typeFile {
  padding: 2px !important;
}
.dropdownPageSize {
  color: #000;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.checkBoxAlignMent {
  // position: relative;
  //   bottom: 2px;
  position: absolute;
  bottom: 0px;
  top: -2px;
}
.checkBoxAlignMentTrain {
  position: absolute;
  bottom: 8px;
  // top: 22px;
}
.uploadiconAlignMent {
  position: relative;
  bottom: 4px;
}
.manageCreativeImage {
  // position: absolute;
  //   left: 0;
  //   top: 0;
  // height: 125px;
  // width: 100%;
  height: 130px;
  width: 215px;
  // box-shadow: 0px 0.39rem 1.39rem -0.94rem #80808038
  box-shadow: 0px 0px 5px 0 #80808038;
}
@media screen and (max-width: 768px) {
  .manageCreativeImage {
    // position: absolute;
    //   left: 0;
    //   top: 0;
    // height: 120px;
    // width: 100%;
    height: 130px;
    width: 215px;
    // box-shadow: 0px 0.39rem 1.39rem -0.94rem #80808038
    box-shadow: 0px 0px 5px 0 #80808038;
  }
}

.iconsTextPostion {
  position: relative;
  // z-index: 9999;
  // top: 13px;
}
.weekDivWidth {
  width: 14.28%;
}
@media screen and(max-width:576px) {
  .barDiv {
    width: 50px;
  }
  .adjustFontSizesTrain {
    font-size: 10px;
  }
}
@media screen and(max-width:768px) {
  .barDiv {
    width: 50px;
  }
}
@media screen and(max-width:320px) {
  .barDiv {
    width: 36px;
  }
}
.faCheckClass {
  color: white;
  font-size: 20px;
  margin-top: 3px;
}
.faCircleClass {
  color: white;
  font-size: 12px;
  margin-top: 7px;
}
.faExclaClass {
  color: #0e4768;
  font-size: 12px;
  margin-top: 7px;
}
@media screen and(max-width:786px) {
  .faExclaClass {
    color: #0e4768;
    font-size: 12px;
    margin-top: 7px;
  }
  .faCheckClass {
    color: white;
    font-size: 14px;
    margin-top: 5px;
  }
  .faCircleClass {
    color: white;
    font-size: 9px;
    margin-top: 8px;
  }
  .tabRootFirst {
    height: 30px;
    width: 30px;
    // background-color: #22B14C;
    // background-color: #C07D45;
    background-color: #0e4768;
    border-radius: 50%;
    -webkit-justify-content: center;
    justify-content: center;
    text-align: center;
    padding: 3px;
    // border-color: black;
    // border: 5px solid;
  }
  .notVistedDiv {
    height: 30px;
    width: 30px;
    // background-color: red;
    background-color: #fafcfe;
    // background-color: #C07D45;

    border-radius: 50%;
    -webkit-justify-content: center;
    justify-content: center;
    text-align: center;
    padding: 3px;
    // border-color: black;
    // border: 5px solid;
  }
  .activeDiv {
    height: 30px;
    width: 30px;
    // background-color: white;
    // background-color: #C07D45;
    background-color: #0e4768;
    border-radius: 50%;
    -webkit-justify-content: center;
    justify-content: center;
    text-align: center;
    padding: 3px;
    // border-color: black;
    // border: 5px solid;
  }
  .progressCustom {
    height: 3px !important;
    margin-top: 15px !important;
    // width: 30%!important;
    border-radius: 0 !important;
  }
}
.react-bootstrap-table-page-btns-ul {
  margin-top: 3px;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
/* Style The Dropdown Button */
.dropbtn {
  // background-color: #4CAF50;
  color: white;
  padding: 3px;
  // font-size: 16px;
  border: none;
  cursor: pointer;
  width: 58px;
  @media screen and (max-width: 768px) {
    height: 30px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    height: 30px !important;
  }
  @media screen and (min-width: 1440px) {
    height: 34px;
  }
  // height: 34px;
}
.btn-info {
  height: 38px !important;
}
.paymentsHeight {
  height: 100vh;
}
.cropIconManage {
  position: relative;
  top: 20px;
  left: 13px;
  width: 100%;
}
.crossIcon {
  position: relative;
  left: 15px;
  top: 20px;
}
@media screen and (max-width: 786px) {
  .dropzone__img {
    // height: 100px !important;
    margin-left: 13px !important;
    margin-right: 15px !important;
    // width: 100% !important;
  }
  .dropzone__imgs-wrapper {
    width: calc(100% + 30px);
    -webkit-flex-wrap: wrap;
    display: block;
    flex-wrap: wrap;
    overflow: auto;
  }
  .dropImagesWidth {
    width: 100% !important;
  }
  .paymentsHeight {
    height: 100%;
  }
}

// /* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

// /* Dropdown Content (Hidden by Default) */
.dropdown-content-custom {
  display: none;
  position: relative;
  background-color: #f9f9f9;
  min-width: 20px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

// /* Links inside the dropdown */
.dropdown-content-custom a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content-custom a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content-custom {
  display: block;
  position: relative;
}
.img_error {
  color: red;
  padding-left: 15px;
}
.summary_preview_text {
  font-size: 17px;
}
.table_select_checkbox {
  width: 41px;
  height: 21px;
}
.dropdown {
  .dropdown-menu-action {
    // position: absolute;
    // list-style: none;
    // background: white;
    // z-index: 1001;
    // color: black;
    // width: 100%;
    display: none;
  }
}
.dropdown:hover {
  .dropdown-menu-action {
    position: absolute;
    list-style: none;
    background: white;
    z-index: 1001;
    color: black;
    // width: 100%;
    display: block;
    border: 1px solid blue;
    border-top: none;
  }
}
.template__card {
  border: 1px solid #dee2e6;
  border-top: none;
  // height: 78vh;
  // min-height: 660px;
}
.template__card_preview {
  border: 1px solid #dee2e6;
  border-top: none;
}
.template__inner_card_1 {
  border: 1px solid #dee2e6;
  height: 280px;
  margin-bottom: 7px;
  h5 {
    margin-top: -26px;
    height: 20px;
    line-height: 20px;
    // font-size: 15px;
    span {
      background-color: white;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .container-fluid {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.selectBank {
  width: 23% !important;
}
.selectStatus {
  width: 13% !important;
}
.selectStatus2 {
  width: 13% !important;
}
.selectStatusG0 {
  width: 13% !important;
}
.selectStatusGo2 {
  width: 13% !important;
}

@media screen and(max-width:767px) {
  .selectBank {
    width: 100% !important;
  }
  .selectStatus {
    width: 40% !important;
    padding-bottom: 15px;
  }
  .selectStatusG0 {
    width: 52% !important;
    padding-bottom: 15px;
  }
  .selectStatus2 {
    width: 45% !important;
    padding-bottom: 15px;
  }
  .selectStatusGo2 {
    width: 57% !important;
    padding-bottom: 15px;
  }
}
@media screen and (min-width: 768px) and(max-width: 1024px) {
  .selectBank {
    width: 100% !important;
  }
  .selectStatus {
    width: 40% !important;
    padding-bottom: 15px;
  }
  .selectStatusG0 {
    width: 51% !important;
    padding-bottom: 15px;
  }
  .selectStatus2 {
    width: 45% !important;
    padding-bottom: 15px;
  }
  .selectStatusGo2 {
    width: 57% !important;
    padding-bottom: 15px;
  }
  .selectStatusG0 {
    width: 53.5% !important;
    padding-bottom: 15px;
  }
}
.template__inner_card_3 {
  border: 1px solid #dee2e6;
  height: 312px;
  // height: 309px;
  margin-bottom: 0;
  h5 {
    margin-top: -26px;
    height: 20px;
    line-height: 20px;
    // font-size: 15px;
    span {
      background-color: white;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
.cke_contents {
  display: block;
  overflow: hidden;
  // height: 90px !important;
}
// .cke_top, .cke_contents, .cke_bottom {
//   display: block;
//   overflow: hidden;
//   height: 74px;
// }
.cke_bottom {
  height: 20px !important;
}
.cke_top {
  height: 90px !important;
}
.template__inner_card_2 {
  border: 1px solid #dee2e6;
  // height: 296px;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // height: 73vh;
  height: 623px;
  margin-bottom: 0;
  // margin-top: 0.7em; //25px;
  margin-top: 11px; //25px;

  h5 {
    margin-top: -26px;
    height: 20px;
    line-height: 20px;
    // font-size: 15px;
    span {
      background-color: white;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.main-file-uploadSelect {
  background-color: #8080802b;
  margin: 10px 91px;
  padding: 6.25rem 1.25rem;
}
.preview_card_col {
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  height: 588px;
}
.preview_card_text {
  border: 1px solid #dee2e6;
  height: 222px;
  margin-top: 38px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.preview_card_img {
  height: 261px;
  margin-top: 38px;
}
.preview_col_img {
  object-fit: cover;
  height: 212px;
}
.selectedCard {
  // border: 1px solid blue;
  color: blue;
  background-color: lightgoldenrodyellow;
  padding: 8px;
  padding-bottom: 1px;
}
.table_action_btn {
  height: 38px;
}
.table_action_btnInTrain {
  height: 38px;
}
.brand-select-width {
  min-width: 205px;
}
.brand-select-groupbrand {
    font-size: 14px;
    font-weight: bold;
}
.action_Selecter {
  //  .css-bg1rzq-control{
  //    min-height: 10px;
  //    height: 33.5px;
  //    padding: 0;
  //  }
  //  .css-1szy77t-control{
  //    .css-1wy0on6{
  //      .css-16pqwjk-indicatorContainer{
  //       display: none;
  //      }
  //    }
  //  }
  // .css-1szy77t-control{
  //   height: 33.5px;
  // }
  //  .css-16pqwjk-indicatorContainer{
  //   height: 33.5px;
  //  }
}
.coming_soon {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  text-align: center;
}
.viewInfoBadge {
  color: white;
  padding: 6px;
  border-radius: 10px;
  font-size: 12px;
}
.emailTemplate {
  min-height: 555px;
  height: 625px;
  .Header {
    display: flex;
    justify-content: space-between;
    border-bottom: 8px solid #ffcc99;
    padding: 15px;
  }
  .logo {
    height: 70px;
    border-right: 3px solid gray;
    padding-right: 10px;
    // border-radius: 55px;
  }
  .mainDiv1 {
    border-top: 8px solid #9b0a0a;
    padding: 15px;
    padding-bottom: 0;
  }
  .mainDiv2 {
    text-align: center;
    padding: 15px;
    padding-top: 0;
  }
  .Image1 {
    object-fit: contain;
    width: 30%;
  }
  .mainDiv3 {
    padding: 15px;
    .icon {
      width: 50px;
      height: 50px;
      border-radius: 25px;
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    margin: 0 30px;
    .text1 {
      font-size: 14px;
      color: #1f1717;
      margin-bottom: 0;
    }
    .text2 {
      font-size: 14px;
      color: #1f1717;
      margin-top: 0;
    }
    .orderNow {
      background-color: #266b37;
      padding: 10px;
      border: navajowhite;
      border-radius: 10px;
      color: white;
      font-weight: 700;
      width: 120px;
    }
  }
}
.templates {
  [type~="file"] {
    height: 10% !important;
    font-size: smaller;
  }
  [name~="promocodes"] {
    // display: none;
  }
}
.react-bootstrap-table-pagination-list {
  margin-top: 4px;
}
.comingsoon_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 600px;
  // font-size: 55px;
  font-weight: 600;
}
.csv-file-input {
  border: 1px solid #dee2e6;
  /* height: 71%; */
  height: calc(1.5em + 0.75rem + 2px);
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  border-radius: 3px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.template-img {
  display: flex;
  width: 200px;
  height: 150px;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.imageSelection {
  .css-1hwfws3 {
    justify-content: center;
  }
  .css-151xaom-placeholder {
    justify-content: start;
    text-align: start;
  }
}
.selectedTemplateImage {
  border: 5px solid blue;
}
.account_Settings {
  span {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }
}
// input#file-input-btn{
//   display: none;
// }
// // input{
// //   #hiii{
// //     display:none
// //   }
// // }
// input #file-upload-button{
//   display:none !important
// }
// input{
//   // #file-upload-button{
//     font-size: 30px;
//   // }
// }

.approvedBadge {
  color: white;
  background: green;
  padding: 6px;
  border-radius: 35px;
}
.rejectedBadge {
  color: white;
  background: red;
  padding: 6px;
  border-radius: 35px;
}
.pendingBadge {
  color: white;
  background: orange;
  padding: 6px;
  border-radius: 35px;
}
.deletedBadge {
  color: white;
  background: red;
  padding: 6px;
  border-radius: 35px;
}
.updatedBadge {
  color: white;
  background: yellow;
  padding: 6px;
  border-radius: 35px;
}

.mainNavBox {
  // position: sticky;
  //   top: 0;
  //   z-index: 9999;
  position: fixed;
  top: 0;
  height: 54px;
  z-index: 101;
  background: white;
  box-shadow: 0 2px 15px 0 #0000000d;
}
.mainPanelHeight {
  height: 100vh !important;
  position: relative;
}
.mainPanelHeightPercentage {
  height: 100% !important;
}
@media screen and (max-width: 767px) {
  .mobileContainer {
    padding-top: 59px;
    height: calc(100vh - 0px);
    //  height: calc(100vh - 64px) !important;
    // max-height: 110vh;
    overflow: auto;
    // padding-bottom: 60px;
  }
  .mainPanelHeight {
    position: relative;
    // height: calc(var(--vh, 1vh) * 100) !important;
    // height: calc(100vh + 64px) !important;
    overflow: hidden;
    //  max-height: calc(100vh - 120px) !important;
  }

  .mainNavBox {
    //  position: sticky;
    //   top: 0;
    //   z-index: 9999;
    width: 100%;
    position: fixed;
    top: 0;
    height: 54px;
    z-index: 101;
    background: white;
    box-shadow: 0 2px 15px 0 #0000000d;
  }
}
.customMargin {
  @media screen and (min-width: 480px) {
    margin-left: 18px;
  }
}
html {
  height: 100vh;
}
body {
  height: 100%;
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
// Manage Creatives page drag and drop start
.drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  // margin: 0;
  // width: 800px;
  height: 300px;
  border: 4px dashed #9799a8;
}
.drop-inner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drop-message {
  text-align: center;
  color: #4aa1f3;
  font-family: Arial;
  font-size: 20px;
}

.file-input {
  display: none;
}
// Manage Creatives page drag and drop end

.insight {
  .cardstyle {
    min-height: 230px;
    flex: 1;
    margin: 20px 20px;
    padding-top: 10px;
    border-radius: 10px;
    cursor: pointer;
    align-items: center;
    display: flex;
    // box-shadow: 9px 17px 45px -29px
    box-shadow: 16px 21px 11px -15px rgba(0, 0, 0, 0.05);
  }

  .f-16 {
    font-size: 16px;
  }
  .l-space {
    letter-spacing: 2;
  }
}

.navbar-light .navbar-toggler {
  color: white;
  border-color: white;
  background: #e3dbdb;
}
.navbar {
  padding: 0.5rem 2rem;
}

.progressBar {
  height: 1.5rem !important;
}
.chartCard {
  display: block;
}
.search {
  font-size: 2rem;
}
.search.reactselect__value-container,
.reactselect__control.reactselect__value-container {
  padding: 0.6rem;
  font-size: 1rem;
  // min-width: 160px;
}
.reactselect__value-container {
  min-width: 160px;
}
.search.reactselect__menu {
  font-size: 1rem;
}

.search.reactselect__indicator.reactselect__dropdown-indicator {
  padding: 0.94rem;
}
.search.reactselect__control {
  border: unset !important;
  box-shadow: 0px 0.39rem 0.39rem -0.44rem #80808038;
}
.search.reactselect__dropdown-indicator,
.search.reactselect__indicators,
.reactselect__indicator.reactselect__dropdown-indicator {
  // background: #2d3152;
  background: #323657;
  border-top-right-radius: 0.16rem;
  border-bottom-right-radius: 0.16rem;
}
.search.reactselect__indicator-separator {
  display: none;
}
.search.reactselect__indicator.reactselect__dropdown-indicator,
.reactselect__indicator.reactselect__dropdown-indicator {
  color: white;
  svg {
    height: 1.66rem;
    width: 1.66rem;
  }
}

.search.reactselect__control.reactselect__control--is-focused,
.reactselect__control.krowd__control--is-focused:hover {
  border: 1px solid gray;
  box-shadow: 0 0 0 1px transparent;
}

.search.reactselect__indicator.reactselect__dropdown-indicator {
  padding: 0.5rem;
}
// }
.spinner-block {
  min-width: 400px;
  min-height: 200px;
  align-items: center;
}
.p-l-10 {
  padding-left: 10px;
}
/* Responsiveness */
@media screen and (max-width: 1024px) {
}
@media (max-width: 1400px) and (min-width: 1024px) {
}
@media (max-width: 1024px) and (min-width: 993px) {
}
@media (max-width: 992px) and (min-width: 769px) {
}
@media (max-width: 768px) and (min-width: 415px) {
  .spinner-block {
    min-width: 300px;
    min-height: 200px;
    align-items: center;
  }
}
@media (max-width: 768px) and (min-width: 320px) {
  .insight .cardstyle {
    min-height: 200px;
    /* -webkit-flex: 1 1; */
    flex: 0;
    margin: 10px;
    padding-top: 10px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: block;
    min-width: 250px;
    text-align: center;
  }
  .classic {
    width: 100%;
  }
}
@media (max-width: 414px) and (min-width: 375px) {
  .spinner-block {
    min-width: 250px;
    min-height: 200px;
    -webkit-align-items: center;
    align-items: center;
  }
  .p-r-0 {
    padding: 0px !important;
  }
}
@media (max-width: 375px) and (min-width: 360px) {
  .insight .cardstyle {
    min-height: 180px;
    /* -webkit-flex: 1 1; */
    flex: 0;
    margin: 10px;
    padding-top: 10px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: block;
    min-width: 250px;
  }
  .card-body {
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
    width: 300px;
  }
  .chartCard {
    display: block;
    min-width: 280px;
  }
  .apexcharts-canvas,
  .apexcharts-svg {
    width: 280px;
  }
  .spinner-block {
    min-width: 200px;
    min-height: 200px;
    align-items: center;
  }
  .p-r-0 {
    padding: 0px !important;
  }
}
@media (max-width: 360px) and (min-width: 320px) {
  .p-r-0 {
    padding: 0px !important;
  }
}
@media (max-width: 320px) {
  .insight .cardstyle {
    min-height: 180px;
    /* -webkit-flex: 1 1; */
    flex: 0;
    margin: 10px;
    padding-top: 10px;
    border-radius: 10px;
    cursor: pointer;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-flex;
    display: block;
    min-width: 250px;
  }
  .card-body {
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
    width: 300px;
  }
  .spinner-block {
    min-width: 150px;
    min-height: 150px;
    align-items: center;
  }
}

.va-m {
  vertical-align: middle !important;
}

.range-link {
  color: blue !important;
  text-decoration: underline;
}

.css-17vdz66 {
  display: grid !important;
}

.skeleton {
  .hide-text {
    background: #e2e2e2;
    color: transparent;
    position: relative;
    overflow: hidden;
    height: 20px;
  }

  .hide-text::before {
    content: "";
    position: absolute;
    left: 0%;
    top: 0;
    height: 100%;
    width: 50px;
    background: linear-gradient(to right, #e2e2e2 25%, #d5d5d5 50%, #e2e2e2 100%);
    animation-name: gradient-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    filter: blur(5px);
  }

  @keyframes gradient-animation {
    from {
      left: 0%;
    }
    to {
      left: 100%;
    }
  }
}

.search {
  .reactselect__value-container {
    min-width: 130px;
  }
}

.overFlowAuto {
  overflow: auto;
  justify-content: center;
}
/* width */
.scrollbarCustom::-webkit-scrollbar {
  width: 2px !important;
  height: 4px;
}

/* Track */
.scrollbarCustom::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey !important;
  border-radius: 10px !important;
}

/* Handle */
.scrollbarCustom::-webkit-scrollbar-thumb {
  background: grey !important;
  border-radius: 10px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.upload-btn-wrapper .btn {
  width: 100px;
  cursor: pointer;
}
.form-box .file-name {
  display: flex;
  font-size: 13px;
  padding-left: 10px;
  // padding-top: 5px;
}
.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  width: 100px;
  height: 30px;
}

.amex-dining-signup-bg {
  margin-top: 8%;
}
.amex-dining-signupBg {
  background-color: #fff;
}
.amex-dining-signup-header {
  background-color: #ffffff !important;
    color: #fff;
    height: 115px;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}
.divid-logo {
    position: absolute;
    margin-left: 6%;
    width: 1px;
    height: 60px;
    // margin-top: 2%;
    background-color: #201d1d47;
}
.amex-signup-krowd-logo {
  margin-bottom: 2em;
  position: absolute;
  margin-left: 8%;
}
.amex-signup-amex-logo {
  margin-bottom: 2em;
  position: absolute;
  // margin-left: 10%;
}
.amex-singup-title {
    font-size: 26px;
    color: #006fcf;
    text-align: left;
    margin-left: 10px;
    margin-top: 10px;
}
.amex-dining-signup-contain {
  background-color: #ededed !important;
}
.amex-dining-trainComponentBg {
  background-color: #f8f4f3;
  // background-image: url("../images/background-image1_1920x1080.jpg");
  // background-image: url("../images/background-image2_1920x1080.jpg");
}
.bg-1-amex-signup {
  min-height: 500px;
}
.bg-1-amex {
  // background-image: url("../images/background-image1_1920x1080.jpg");
  background-image: url("../images/background-image2_1920x1080.jpg");
  color: #fff;
  z-index: 100;
}
.c-fff {
  color: #fff !important;
}
.f-s-11 {
  font-size: 11px !important;
}
.m-0 {
  margin: 0 !important;
}

.m-t-5 {
  margin-top: 5px;
}
.m-b-5 {
  margin-bottom : 5px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-25 {
  margin-top: 25px;
}
.m-t-60 {
  margin-top: 60px;
}
.m-b-30 {
  margin-bottom: 30px;
}
.p-0 {
  padding: 0px !important;
}
.filter-title {
    font-size: 12px;
    font-weight: bold;
    padding-left: 5px;
}
.p-l-r-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.rm-ex-p-m {
  margin-right: 0px !important;
  padding-right: 0px !important;
}
.w-70 {
  width: 70px;
}
.amex-branch-label {
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
}
.amex-dining-contain .amex-signup {
  margin-top: 50px;
}
.amex-dining-contain .amex-signup-1 {
  margin-top: 140px;
}
.amex-dining-contain .card .form label {
  margin-bottom: 0px !important;
}
.amex-dining-contain .amex-form-title {
  font-size: 15px;
  // font-weight: bold;
  // background-color: rgba(0, 0, 0, 0.6);
  // padding: 5px;
}
.amex-dining-contain .form-box {
  padding-top: 0px;
}
.amex-dining-contain .card {
  box-shadow: none;
  // background-color: unset;
  background-color: rgba(0,0,0,0.3);
}
.amex-dining-contain .btn-info {
  background-color: #006fcf;
    border-color: #006fcf;
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.amex-dining-contain .border-1 {
  width: 80%;
  background-color: #e36952;
  height: 5px;
}
.amex-dining-contain .border-2 {
  width: 20%;
    background-color: #1f2129;
    height: 5px;
}
.amex-signup .card .form {
  padding: 0px !important; 
}
.logos-contain {
  position: relative;
  width: 100%;
  z-index: 101;
}
.amex-dining-bg {
  margin-bottom: 150px;
}
.customer-am-title {
  font-weight: bold;
  font-size: 2.375rem;
}
.customer-am-title-signup {
  font-weight: bold;
  font-size: 1.375rem;
}
.logo-container-style {
  margin-bottom: 2em;
  text-align: center;
  // background-color: rgba(0,0,0,.6);
  //   border-radius: 3px;
}
.krowd-logo-container-style {
  margin-bottom: 2em;
  position: absolute;
  left: 40px;  
}
.amex-logo-container-style {
  margin-bottom: 2em;
  position: absolute;
  right: 40px;
}
.talk-to-us-area { 
  position: absolute;
  z-index: 101;
  right: 15px;
  bottom: 8px;
}
@media screen and (min-width: 550px) and (max-width: 1100px) {
  .amex-dining-contain .amex-signup {
    margin-top: 115px;
  }
  .amex-dining-contain .amex-signup-1 {
    margin-top: 160px;
  }
  .krowd-logo-container-style {
    left: 0;  
  }
  .amex-logo-container-style {
    right: 0;
  }
  .bg-1-amex {
    background-image: url("../images/amex-mobile.jpg");
  }
  .customer-am-title {
    font-size: 1.5rem;
  }
  .talk-to-us-area {
    bottom: 60px;
  }
}

@media screen and (min-width: 250px) and (max-width: 1100px) {
  .amex-dining-contain .amex-signup {
    margin-top: 115px;
  }
  .amex-dining-contain .amex-signup-1 {
    margin-top: 160px;
  }
  .krowd-logo-container-style {
    left: 0;  
  }
  .amex-logo-container-style {
    right: 0;
  }
  .bg-1-amex {
    background-image: url("../images/amex-mobile.jpg");
  }
  .customer-am-title {
    font-size: 1.5rem;
  }
  .talk-to-us-area {
    bottom: 60px;
  }
}

@media screen and (min-width: 250px) and (max-width: 800px) {
  .amex-dining-contain .amex-signup {
    margin-top: 115px;
  }
  .amex-dining-contain .amex-signup-1 {
    margin-top: 160px;
  }
  .krowd-logo-container-style {
    left: 0;  
  }
  .amex-logo-container-style {
    right: 0;
  }
  .logos-contain {
    margin-left: 50px;
    margin-right: 50px;
  }
  .bg-1-amex {
    background-image: url("../images/amex-mobile.jpg");
  }
  .customer-am-title {
    font-size: 1.5rem;
  }
  .talk-to-us-area {
    bottom: 60px;
  }
  .divid-logo {
    margin-left: 20%;
  }
  .amex-signup-krowd-logo {
    margin-left: 25%;
  }
  .keep-top-space {
    margin-top: 85px !important;
  }
}

@media screen and (min-width: 550px) and (max-width: 1100px) {
  .amex-dining-contain .amex-signup {
    margin-top: 115px;
  }
  .amex-dining-contain .amex-signup-1 {
    margin-top: 160px;
  }
  .krowd-logo-container-style {
    left: 0;  
  }
  .amex-logo-container-style {
    right: 0;
  }
  .logos-contain {
    margin-left: 50px;
    margin-right: 50px;
  }
  .bg-1-amex {
    background-image: url("../images/amex-mobile.jpg");
  }
  .customer-am-title {
    font-size: 1.5rem;
  }
  .talk-to-us-area {
    bottom: 60px;
  }
  .divid-logo {
    margin-left: 10%;
  }
  .amex-signup-krowd-logo {
    margin-left: 13%;
  }
}

@media screen and (min-width: 250px) and (max-width: 1100px) {
  .amex-dining-contain .amex-signup {
    margin-top: 115px;
  }
  .amex-dining-contain .amex-signup-1 {
    margin-top: 160px;
  }
  .krowd-logo-container-style {
    left: 0;  
  }
  .amex-logo-container-style {
    right: 0;
  }
  .logos-contain {
    margin-left: 50px;
    margin-right: 50px;
  }
  .bg-1-amex {
    background-image: url("../images/amex-mobile.jpg");
  }
  .customer-am-title {
    font-size: 1.5rem;
  }
  .talk-to-us-area {
    bottom: 60px;
  }
}

@media only screen and (min-width: 200px) and (max-width: 750px) {
  .amex-dining-contain .amex-signup {
    margin-top: 115px;
  }
  .amex-dining-contain .amex-signup-1 {
    margin-top: 160px;
  }
  .krowd-logo-container-style {
    left: 0;  
  }
  .amex-logo-container-style {
    right: 0;
  }
  .logos-contain {
    margin-left: 50px;
    margin-right: 50px;
  }
  .bg-1-amex {
    background-image: url("../images/amex-mobile.jpg");
  }
  .customer-am-title {
    font-size: 1.5rem;
  }
  .talk-to-us-area {
    bottom: 5px;
  }
  .divid-logo {
    margin-left: 20%;
  }
  .amex-signup-krowd-logo {
    margin-left: 25%;
  }
  .keep-top-space {
    margin-top: 85px !important;
  }
}
.c-pointer {
  cursor: pointer;
}
.data-upload-date {
  font-size: 12px;
  font-weight: bold;
}
.upload-date-box {
  min-height: 89px;
}
.upload-date-box .title {
  font-weight: bold !important;
  font-size: 11px !important;
}
.upload-date-box .sub-title {
  font-weight: unset !important;
  font-size: 11px !important;
}
.link-pointer-icon {
  cursor: pointer;
}
.m-l-10 {
  margin-left: 10px !important;
}
.inv-no-dropdown {
    width: 100px !important;
    margin-left: 10px;
}

.invoice-data-toogle {
  display: inline-flex;
  justify-content: right;
}

.invoice-data-toogle .title {
    font-size: 14px;
    // font-weight: bold;
    // margin-top: -2px;
    padding-top: 3px;
}

.invoice-data-toogle .toggle-button-cover {
  display: table-cell;
  position: relative; 
  box-sizing: border-box;
  margin-left: 5px;
  width: 75px;
  margin-right: 5px;
} 

.invoice-data-toogle .button-cover,
.invoice-data-toogle .knobs,
.invoice-data-toogle .layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.invoice-data-toogle .button {
  position: relative;
  top: 50%;
  width: 55px;
  height: 25px;
  margin: -14px auto 0 auto;
  overflow: hidden;
}

.invoice-data-toogle .button.r,
.invoice-data-toogle .button.r .layer {
  border-radius: 100px;
}

.invoice-data-toogle .button.b2 {
  border-radius: 2px;
}

.invoice-data-toogle .checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.invoice-data-toogle .knobs {
  z-index: 2;
}

.invoice-data-toogle .layer {
  width: 100%;
  background-color: #ccd2d5;
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 1 */
.invoice-data-toogle #button-1 .knobs:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 20px;
  height: 18px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  // background-color: #958a8a;
  background-color: #3d81f5;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

.invoice-data-toogle #button-1 .checkbox:checked + .knobs:before {
  content: "";
  left: 30px;
  background-color: #3d81f5;
}

.invoice-data-toogle #button-1 .checkbox:checked ~ .layer {
  background-color: #ccd2d5;
}

.invoice-data-toogle #button-1 .knobs,
.invoice-data-toogle #button-1 .knobs:before,
.invoice-data-toogle #button-1 .layer {
  transition: 0.3s ease all;
} 