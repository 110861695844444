.mb-2rem {
	margin-bottom: 2rem !important;
}
.font-size-normal {
  font-size: 1rem;
}
.mr-2rem {
	margin-right: 2rem !important;
}

.va-m {
	vertical-align: middle
}