.rc-slider {
	margin-left: 8px;
}
.rc-slider-dot {
  display : none;
}
.rc-slider-track {
  background-color : #327bfa;
}

.rc-slider-handle {
  border-width : 0.22rem;
  border-color : #327bfa;
}
.rc-slider-handle:active {
  border-color: #327bfa;
  box-shadow: 0 0 0.27rem #327bfa;
}
.rc-slider-handle:hover {
  border-color: #2776fe;
}

.rc-slider-handle:focus {
  border-color: #2776fe;
}