
.card {
  box-shadow: 0px 0.39rem 1.39rem -0.44rem #80808038;
  border : unset;
  border-radius: 0.22rem;
  margin-bottom: 1.66rem;
  padding : 1rem 1.39rem;

  .card-title {
    font-size : 1rem;;
    padding-top: 1.77rem;  
  }
}

// @media(max-width : 960px) {
//   .card {
//     .card-body {
//       padding : 0.83rem 0.55rem;
//       p {
//         font-size : 0.72rem;
//         margin-bottom : 0;
//       }
//     } 
//   }  
// }

