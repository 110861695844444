.custom-multiselect {
	.dropdown {
		align-content: center;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
    .dropdown-menu {
    	box-shadow: 0 0 18px -4px grey;
    	.dropdown-item {
    		padding: 0.5rem 1.5rem;
		    display: flex;
		    align-items: center;
    	}
    }
	}
	.dropdown-toggle {
		height: calc(100% - 0px);
    border-color: white;
    display: flex;
    padding: 8px;
    -webkit-transition: color 150ms;
    transition: color 150ms;
    box-sizing: border-box;
    background: #2d3152;
    color: white;
    &:after {
    	content: none;
    }
	}
	.reactselect__dropdown-indicator {
		display: none;
	}
}