.cursor-pointer {
  cursor : pointer;
}
.table-bordered thead th {
  white-space: nowrap;
  text-align: center;
}
.nowrap { 
  white-space : nowrap;
}

.condenced th {
  white-space: nowrap;
  font-size: 13px;
  padding: 20px 5px;
  text-align: center;
}
.table td.vhalign, .table th.vhalign {
  vertical-align : middle;
  text-align : center;
  max-width : 250px;
  // text-transform: capitalize;
}

  .table  td.checkBox{
    @include smallDisplay() {
      max-width: 40px !important;
      min-width: 40px !important;
    }
  }
  .table td.actions{
    @include smallDisplay() {
      max-width: 70px !important;
      min-width: 70px !important;
    }
  }
  .table td.merchantId{
    @include smallDisplay() {
      max-width: 180px !important;
      min-width: 180px !important;
    }
  }
  .table td.tripadvisorWidth{
    @include smallDisplay() {
      max-width: 120px !important;
      min-width: 120px;
    }
  }
  .table td.ContactDetails{
    @include smallDisplay() {
      max-width: 160px !important;
      min-width: 160px !important;
    }
  }

@media screen and (max-width:786px){
  .table td.vhalign, .table th.vhalign {
  max-width: 800px;
    min-width: 120px;
}
}

// .wi-10{
// width: 10%;
// }
// .wi-60{
//   width: 60%;
//   }

td .btn-simple.btn-icon {
  padding-top : 0;
  padding-bottom : 0;
}

.react-bootstrap-table table {
  table-layout: auto;
}

.react-bootstrap-table-page-btns-ul {
  justify-content : flex-end;
}

.react-bootstrap-table-pagination-list-hidden {
    display: none;
}
.react-bootstrap-table th.sortable {
  outline : none;
}