/* .container{
    display: flex;
    margin-top: 10px;
} */
@import "../../assets/newstyles/media";

.link {
  text-decoration: none;
  color: inherit;
}

svg#hsbarExample {
  border: 2px solid #363636;
  border-radius: 8px;
}

.similarityCharts > div {
  display: inline-block;
}
.radar-example {
  // height: 700px;
  // width: 900px;
  // min-height: 700px;
  // min-width: 900px;
  // height: 700px;
  width: 100%;
  text-align: center;
  // min-height: 700px;
  // min-width: 900px;
  @include extraSmallDisplay() {
    height: 250px;
    width: 450px;
  }

  @include tabToTabletDisplay() {
    // height: 500px;
    // margin: 15%;
    width: 100%;
  }

  @include smallDisplay() {
    height: 250px;
    width: 450px;
  }
}
.chartjs-render-monitor {
  /* max-height: 450px !important; */
  display: inline-block !important;
}
canvas#chart.chartjs-render-monitor {
  min-height: 250px;
  max-height: 450px;
}
