.navbar-toggle {
  display: none
}

@media (max-width: 480px) {
  .navbar-toggle {
    display: block;
    margin-right: 1rem;
  } 
  .main-nav .navbar-toggle i {
    font-size: 2.5rem;
    color: #2a2c44;
    margin-top: 1.2rem;
    &:hover {
      color: #3d81f5;
    }
  }
  
}